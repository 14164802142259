

.PairTxns {
  padding: 6px;
  height: 250px;
  border-top: 3px solid #ddd;
}

.transaction-list-container {
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.transaction-list-header {
  display: flex;
  background-color: #f2f2f2;
  font-weight: bold;
  height: 40px;
  align-items: center;
  padding: 0 8px;
  border-bottom: 1px solid #ddd;
}

.transaction-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.transaction-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.column {
  flex: 1;
  padding: 8px;
  text-align: center;
}


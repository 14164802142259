.token-info {
  display: flex;
  min-width: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px;
  border: 1px solid #ccc;
  margin: 8px;
  transition: background-color 0.3s ease;
  border-radius: 8px; /* Add rounded corners */
}

.token-info.selected {
  background-color: #e0e0e0;
}

.token-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
}
/* BarStat.css */

.MainBar {
    display: flex;
    justify-content: space-between;
  }
  
  .network-details,
  .dex-details {
    display: flex;
    align-items: center;
  }
  
  .network-info,
  .dex-info {
    margin-left: 10px; /* Adjust the margin as needed */
  }
  
/* ExchangeCarousel.css */
.carousel-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.no-item-carousel {
  height: 100px;
  background: gray;
}

/* PairList.css */

.pair-list-container {
    margin-top: 20px;
  }
  
  h2 {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .pair-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  thead {
    background-color: #f2f2f2;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    font-weight: bold;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
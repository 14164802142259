/* Nav.css */

/* Beta information styling */
.beta-info {
  background-color: #f8d7da; /* Bootstrap's danger color for emphasis */
  color: #721c24; /* Bootstrap's danger text color for emphasis */
  padding: 10px;
  text-align: center;
}

/* Styles for mobile devices */
@media only screen and (max-width: 768px) {
    .navbar {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .mobile-menu-button {
      font-size: 20px;
      background: none;
      border: none;
      cursor: pointer;
      display: block;
      margin-bottom: 10px;
    }
  
    .nav-list {
      display: none;
      text-align: center;
    }
  
    .nav-list.show {
      display: block;
    }
  
    .rightNav {
      display: none;
      text-align: center;
    }
  
    .rightNav.show {
      display: block;
    }

    .logo {
      height: 120px;
      width: 120px;
    }
  }
  
  /* Styles for larger screens */
  @media only screen and (min-width: 769px) {
    .mobile-menu-button {
      display: none;
    }
  
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .nav-list {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo{
      height: 150px;
    }

    .logo {
      height: 150px;
      width: 150px;
    }
  }
  
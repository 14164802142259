/* Section.css */

.section {
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .box-main {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Additional styles to manage overflow */
    word-wrap: break-word; /* Breaks long words to prevent overflow */
    max-width: 100%; /* Ensures the box doesn't expand beyond its parent */
  }
  
  .content {
    text-align: center;
  }
  
  .text-big {
    font-size: 2em;
  }
  
  .text-small {
    font-size: 1em;
  }
  
  /* Media query for mobile devices */
  @media only screen and (max-width: 768px) {
    .box-main {
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }
  
    .text-big {
      font-size: 1.5em;
    }
  
    .text-small {
      font-size: 0.8em;
    }
  }
  
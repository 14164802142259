/* YourComponent.css */

.sections-container {
    display: flex;
    flex-direction: column;
  }
  
  /* Default gap between sections */
  .section {
    margin-bottom: 20px; /* Default gap */
  }
  
  /* Media query for smaller screens */
  @media only screen and (max-width: 768px) {
    .sections-container {
      gap: 10px; /* Adjusted gap for smaller screens */
    }
  
    /* Reduce gap between sections for smaller screens */
    .section {
      margin-bottom: 10px;
    }
  }
  
  /* Media query for larger screens */
  @media only screen and (min-width: 1200px) {
    .sections-container {
      gap: 30px; /* Adjusted gap for larger screens */
    }
  
    /* Increase gap between sections for larger screens */
    .section {
      margin-bottom: 30px;
    }
  }
  
/* Common styles for all screen sizes */
.ChartContainer {
  display: flex;
  flex-direction: column;
}

.BarStat {
  max-width: 100%;
}

.RowContainer {
  display: flex;
}

.PairStat {
  width: 100%;
}

.TVChartContainer {
  padding: 10px;
  height: calc(80vh - 150px);
  width: 100%;
}

.TransactionList {
  width: 100%;
  height: auto; /* Adjust the height as needed */
}

/* Media query for screens larger than 768px (adjust as needed) */
@media (min-width: 768px) {
  .RowContainer {
    flex-wrap: nowrap; /* Ensure items stay in a single row */
  }

  .PairStat {
    width: 25%;
  }

  .TVChartContainer {
    width: 75%;
  }
}
